<template>
  <div class="d-flex align-center">
    <div class="mr-3">
      <IconLogo
        :height="xs ? 30 : height"
        :width="xs ? 30 : width"
        :color="color"
        :accent="accent"
      />
    </div>
    <div :class="{ 'd-none d-sm-inline': xsHideText }">
      <div :class="titleClass" :style="'color:' + color">{{ title }}</div>
      <div :class="subTitleClass" :style="'color:' + accent">
        {{ subTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import IconLogo from "@/components/shared/IconLogo";

export default {
  props: [
    "title",
    "titleClass",
    "subTitle",
    "subTitleClass",
    "xsHideText",
    "height",
    "width",
    "color",
    "accent",
    "education",
    "healthcare",
    "it",
    "internationalMedical",
    "patRec",
  ],
  components: { IconLogo },
  computed: {
    xs() {
      if (window.innerWidth <= 375) return true;
      else return false;
    },
  },
};
</script>

<style scoped></style>
