<template>
  <svg :width="width" :height="height" viewBox="0 0 39 40">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-224.000000, -30.000000)" :fill="color">
        <g transform="translate(165.000000, 14.000000)">
          <g transform="translate(59.478261, 16.869565)">
            <path
              d="M17.6668627,20.7166587 L29.9857183,34.9143661 C26.8597953,37.0967409 23.0495553,38.3871453 18.9286979,38.3742049 C18.5047627,38.3725731 18.0850457,38.3522487 17.6668627,38.3232961 L17.6668627,38.3232961 L17.6668627,20.7166587 Z M11.3877704,21.8617632 L11.3877704,36.7921058 C5.42871,34.2051614 0.959462609,28.6667767 0.0128465217,21.8617632 L0.0128465217,21.8617632 L11.3877704,21.8617632 Z M34.5661169,7.9625361 C36.8794495,11.1431048 38.2291012,15.0369431 38.1602669,19.1921217 C38.2137621,23.4596596 36.803329,27.4457244 34.3864573,30.6671335 L34.3864573,30.6671335 L23.9263208,19.0753526 Z M19.4277948,0.00290792332 C23.637044,0.0792200972 27.544304,1.55906271 30.6897844,3.97938575 L30.6897844,3.97938575 L17.6668627,17.7395444 L17.6668627,0.0537187929 C18.2470653,0.0124948798 18.8334035,-0.00802120712 19.4277948,0.00290792332 Z M11.3877704,1.58473658 L11.3877704,16.5944592 L-1.22568622e-13,16.5944592 C0.90405,9.87822092 5.30657217,4.21769831 11.3877704,1.58473658 L11.3877704,1.58473658 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["height", "width", "color"],
};
</script>

<style scoped></style>
