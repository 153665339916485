<template>
  <div>
    <div class="hero" :class="{ dark: dark }">
      <v-container :fill-height="true">
        <div class="container text-center">
          <div class="d-flex justify-center">
            <TextLogo
              :width="xs ? 200 : 500"
              color="#ffffff"
              accent="#97D7D8"
            />
          </div>

          <div class="text-h4 text-sm-h3 font-weight-black my-3">
            Connecting Healthcare
          </div>

          <div class="my-12">
            <v-btn
              x-large
              elevation="0"
              class="mr-sm-2 mb-1 mb-sm-0"
              rounded
              :dark="!dark"
              @click="scrollToContent()"
            >
              Learn More
              <v-icon class="ml-1">mdi-chevron-down</v-icon>
            </v-btn>
          </div>

          <div class="d-none d-sm-flex justify-center text-center my-3 my-sm-6">
            <div>
              <div class="text-overline mb-0">Part of the</div>
              <div class="d-flex align-center">
                <div class="mr-3">
                  <GroupIconLogo :height="50" :width="50" color="#ffffff" />
                </div>
                <div class="text-h6 font-weight-black">Holden Knight Group</div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <div ref="bottom"></div>
  </div>
</template>

<script>
import TextLogo from "@/components/shared/TextLogo";
import GroupIconLogo from "@/components/shared/GroupIconLogo";

export default {
  name: "Hero",
  props: ["dark"],
  components: { TextLogo, GroupIconLogo },
  computed: {
    xs() {
      if (window.innerWidth <= 375) return true;
      else return false;
    },
  },
  data() {
    return {};
  },
  methods: {
    scrollToContent() {
      this.$refs["bottom"].scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.hero.dark {
  background: linear-gradient(#100844, #21118d);
  color: white !important;
}

.hero .container {
  width: 100%;
}

.hero .fill-height {
  height: 100vh;
  max-height: 100vh;
}

.text-overline {
  line-height: 0;
}
</style>
