<template>
  <div>
    <TopNav :absolute="true" :dark="true" />
    <HeroPanel :dark="true" />

    <v-container
      v-touch="{
        right: () => toHome(),
      }"
    >
      <div class="my-9">
        <div class="text-h3 font-weight-black my-9" data-aos="fade-in">
          About
        </div>

        <v-row class="my-6 my-sm-9">
          <v-col cols="12" sm="4">
            <v-card to="/patient">
              <v-card-text>
                <IconTextLogo
                  title="PatRec"
                  subTitle="Patient"
                  titleClass="text-h5
              font-weight-black"
                  subTitleClass="text-h6
              "
                  :height="50"
                  :width="50"
                  accent="#97D7D8"
                  color="#21118D"
                  class="py-1"
                />
                <div class="text-body-1 py-1">
                  Create your own medical profile and request a consultation or
                  second opinion by sharing your medical profile with a
                  physician.
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn text rounded color="#97D7D8">
                  Learn more<v-icon class="ml-1">mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card to="/consultant">
              <v-card-text>
                <IconTextLogo
                  title="PatRec"
                  subTitle="Consultant"
                  titleClass="text-h5
              font-weight-black"
                  subTitleClass="text-h6
              "
                  :height="50"
                  :width="50"
                  accent="#fcaf02"
                  color="#21118D"
                  class="py-1"
                />
                <div class="text-body-1 py-1">
                  We are searching for some of the world's finest healthcare
                  professionals to join and preview our unique and exclusive
                  platform.
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn text rounded color="#fcaf02">
                  Learn more<v-icon class="ml-1">mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card to="/hospital">
              <v-card-text>
                <IconTextLogo
                  title="PatRec"
                  subTitle="Hospital"
                  titleClass="text-h5
              font-weight-black"
                  subTitleClass="text-h6
              "
                  :height="50"
                  :width="50"
                  accent="#d22c52"
                  color="#21118D"
                  class="py-1"
                />
                <div class="text-body-1 py-1">
                  We are searching for some of the world's finest hospitals to
                  join and preview our unique and exclusive platform.
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn text rounded color="#d22c52">
                  Learn more<v-icon class="ml-1">mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeroPanel from "@/components/home/HeroPanel";
import TopNav from "@/components/shared/TopNav";

import IconTextLogo from "@/components/shared/IconTextLogo";

export default {
  components: { HeroPanel, TopNav, IconTextLogo },
  metaInfo: () => ({
    title: "PatRec - Connecting Healthcare - Holden Knight Group",
  }),
};
</script>

<style scoped></style>
